import React from 'react';
import './keepInTouch.scss';
import slacklogo from '../../assets/slack-logo.png'

const KeepInTouch = () => {
  return (
    <div className='container-bg text-center pt-36 pb-28'>
      <p>LET'S MAKE THE NEWS MORE OPEN. TOGETHER.</p>
      <h1 className='text-6xl font-bold mt-5 mb-5'>KEEP IN TOUCH!</h1>
      <p className='primary-color-text'>Join the conversation on our Telegram channel or</p>
      <p><span className='primary-color-text'>email us at </span><a href="">newsengine@protonmail.com</a></p>
      <br />
      <br />
      <div className='flex justify-center'>
        <a href="https://t.me/newsengineAI" className='slack-button flex justify-between w-36'> <div><img className='w-5' src={slacklogo} alt="slack" /></div><div >TELEGRAM</div></a>
      </div>
      <p>NEWSENGINE LLC</p>
      <p className='mb-5'>© Copyright 2023. All Rights Reserved.</p>
    </div>
  );
};

export default KeepInTouch;