import React from "react";
import img1 from "../../assets/whitepaper-alt.png";
import "./whitepaper.scss";

const WhitePaper = () => {
  return (
    <div className="whitePaperMain border border-y-black-100 pt-24 pb-24 text-center">
      <h1 className="text-6xl font-bold primary-color">WHITE PAPER</h1>
      <div className="text-lg font-medium text-center primary-color-text mt-10 mb-10">
        <p>
          Interested in knowing more and getting an in-depth view of how <br />
          NEWSENGINE works? Send us an email to get access to our <br />
          <strong>white paper!</strong>
        </p>
      </div>

      <div className="flex justify-center">
        <a href="" className="white-paper-button">
          READ WHITE PAPER
        </a>
      </div>

      <div className="flex justify-center">
        <img className="w-56" src={img1} alt="article-flow" />
      </div>
    </div>
  );
};

export default WhitePaper;
