import React from 'react';
import './TodaysNews.scss'

const TodaysNews = () => {
  return (
    <div className='news-container flex justify-center'>
      <p className='w-1/2 pt-28 pb-28 text-xl font-semibold text-center'>Today's news is plagued by sensationalistic headlines and misinformation, resulting in fake news and unbalanced reporting. NEWSENGINE provides the blueprint to a more open world for creating and consuming the news, while ensuring accuracy through incentivization.</p>
      <div className='overlay'></div>
    </div>
  );
};

export default TodaysNews;