import React from "react";
import img1 from "../../assets/article-flow.png";
import img2 from "../../assets/reward-flow.png";
import "./HowItWorks.scss";

const HowItWorks = () => {
  return (
    <div className="border border-y-black-100 pt-24 pb-24">
      <h1 className="howItWorksHeading text-6xl font-bold text-center primary-color mb-20">
        HOW IT WORKS
      </h1>
      <div className="howItWorksTextInfo text-lg font-medium text-center primary-color-text mb-24">
        <p>
          Simply put, writers produce and submit content, which is inspected{" "}
          <br /> for <strong>accuracy</strong> by a randomized set of reviewers
          together with AI on the network.
        </p>
        <p className="mt-6">
          Articles deemed acceptable enough (as per NEWSENGINE's guidelines)
          will <br /> be <strong>published</strong> to the network and made
          available to audience.
        </p>
      </div>
      <div className="cardWrapper flex justify-center mb-24">
        <div className="secondary-bg">
          <img src={img1} alt="article-flow" />
        </div>
        <div className="primary-bg">
          <img src={img2} alt="reward-flow" />
        </div>
      </div>
      <div className="howItWorksTextInfo text-lg font-medium text-center primary-color-text">
        <p>
          Each participant on NEWSENGINE is <strong>rewarded</strong> for their
          contribution, in the <br /> form of NEWSENGINE <strong>tokens</strong>
          . Writers earn a reward each time an article is <br /> accepted by the
          majority of assigned reviewers. Reviewers earn a <br /> reward by
          accepting or rejecting articles based on their degree of <br />{" "}
          accuracy. Lastly, readers earn a reward for readership and other{" "}
          <br /> engaging activities, such as commenting.
        </p>
      </div>
    </div>
  );
};

export default HowItWorks;
