import React from "react";
import ourMission from "../../assets/mission-alt.png";
import "./OurMission.scss";

const OurMission = () => {
  return (
    <div className="ourMissionMain text-center pt-20 border border-y-black-100">
      <h1 className="text-6xl font-bold primary-color ">OUR MISSION</h1>
      <div className="text-xl mt-10 mb-10">
        <p>
          NEWSENGINE will focus exclusively on <strong>factual</strong> and
          thorough perspectives of <br /> current affairs, while rewarding all
          parties involved.
        </p>
        <p className="mt-4">
          Our mission is to create news content that is both{" "}
          <strong>empowering</strong> for <br /> its audience, as well as
          representative of the integrity of its writers. <br /> We aspire to
          become the most <strong>trusted and democratic</strong> news <br />{" "}
          alternative to mainstream media.
        </p>
      </div>
      <div className="flex justify-center pb-24">
        <img className="w-56" src={ourMission} alt="ourmission" />
      </div>
    </div>
  );
};

export default OurMission;
