import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import "./bePartOfDnn.scss";

const BePartOfDnn = () => {
  return (
    <div className="bepart-bg pt-32 pb-24 text-center">
      <div className="flex justify-center mb-8">
        <h3 className="title w-72">Be a part of NEWSENGINE</h3>
      </div>
      <h1 className="text-4xl font-bold mt-5 mb-5">
        PUTTING INDEPENDENCE BACK INTO{" "}
      </h1>
      <h1 className="text-6xl font-bold mt-5 mb-5"> NEWS MEDIA</h1>
      <div className="text-lg font-medium">
        <p>
          We believe that NEWSENGINE can be the next step to bringing integrity
          and <br /> independence back into the news, so readers can all just
          focus on <br /> the bare facts, free of tampering and misdirection.
          Feel free to ask <br /> us anything on the channels below!
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <div className="flex justify-between w-44">
          <div className="social-icon">
            <a href="" className="discord-icon">
              <FontAwesomeIcon className="icon" icon={faDiscord} />
            </a>{" "}
          </div>
          <div className="social-icon">
            <a href="https://t.me/newsengineAI" className="tele-icon">
              <FontAwesomeIcon className="icon" icon={faTelegram} />{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BePartOfDnn;
