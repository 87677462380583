import './App.css';
import BePartOfDnn from './components/BePartOfDnn/BePartOfDnn';
import DdnHero from './components/DdnHero/DdnHero';
import Header from './components/Header/Header';
import HowItWorks from './components/HowItWorks/HowItWorks';
import IntroDdn from './components/IntroDdn/IntroDdn';
import KeepInTouch from './components/KeepInTouch/KeepInTouch';
import OurMission from './components/OurMission/OurMission';
import SectionsContainer from './components/SectionsContainer/SectionsContainer';
import TodaysNews from './components/TodaysNews/TodaysNews';
import WhitePaper from './components/WhitePaper/WhitePaper';

function App() {
  return (
    <>
      <Header />
      <DdnHero />
      <IntroDdn />
      <TodaysNews />
      <SectionsContainer />
      <OurMission />
      <HowItWorks />
      <WhitePaper />
	  <BePartOfDnn />
      <KeepInTouch />
      
    </>
  );
}

export default App;
