import React from 'react';
import './articalcard.scss';

const ArticalCard = ({ props }) => {
  return (
    <div className='articalCard w-52 text-center'>
      <div className='count mb-2'>{props.number}</div>
      <h3 className='text-xl font-bold mb-4'>{props.title}</h3>
      <p>{props.details}</p>
    </div>
  );
};

export default ArticalCard;
