import React from 'react';
import ArticalCard from '../ArticalCard/ArticalCard';
import './sectionscard.scss'

const SectionCard = ({ props }) => {
  return (
    <div className='sectionCard w-full border border-y-black-100'>
      <h1 className='text-4xl font-bold text-center primary-color pt-20 pb-20'>{props.title}</h1>
      <div className='sectionCardTextInfoMain flex justify-center pb-20'>
        <div className='sectionCardTextInfo w-1/3 text-center text-lg'>
          <p>{props.text1}</p>
          <p>{props.text2}</p>
          <p>{props.text3}</p>
        </div>
      </div>
      <div className='flex justify-center pb-20'>
        <img className='w-44' src={props.imgUrl} alt={props.title} />
      </div>
      <div className='flex justify-center pb-20'>
        <div className='articalCardsWrapper'>
          {props.articalData.map((d, i) => <ArticalCard props={d} key={i} />)}
        </div>
      </div>
    </div>
  );
};

export default SectionCard;