import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { TwitterShareButton, TelegramShareButton } from "react-share";
import decentralized from '../../assets/decentralized.png'
import factual from '../../assets/factual.png'
import collaborative from '../../assets/collaborative.png'
import './IntroDdn.scss'

const IntroDdn = () => {
  return (
    <div className='intro-container pt-20'>
      <div className='flex justify-center pb-10'>
        <p className='intro-title w-70'>Introducing NEWSENGINE</p>
      </div>

      <div className='flex justify-center'>
        <div className='headingWrapper w-1/2 text-center'>
          <h1 className='text-5xl font-bold'>NEWS BY THE PEOPLE,</h1>
          <h1 className='text-5xl font-bold'>FOR THE PEOPLE.</h1>
          <p className='pt-10 font-semibold text-lg'>NEWSENGINE, is a <strong>Decentralized</strong>, news platform, combining news creation with decentralized networks to deliver factual content, curated by the community.</p>
        </div>
      </div>

      <div className='introBoxesWrapper flex justify-center mt-24 mb-28'>
        <div className='flex justify-around w-1/2 intro-boxes'>
          <div className='intro-boxes-main w-48'>
            <div className='flex justify-center'>
              <img className='w-16' src={decentralized} alt=''/>
            </div>
            <h3 className='text-xl font-bold text-center mt-5'>DECENTRALIZED</h3>
          </div>
          <div className='intro-boxes-main w-48'>
            <div className='flex justify-center'>
              <img className='w-16' src={factual} alt=''/>
            </div>
            <h3 className='text-xl font-bold text-center mt-5'>FACTUAL</h3>
          </div>
          <div className='intro-boxes-main w-48'>
            <div className='flex justify-center'>
              <img className='w-16' src={collaborative} alt=''/>
            </div>
            <h3 className='text-xl font-bold text-center mt-5'>COLLABORATIVE</h3>
          </div>
        </div>
      </div>

      <div className='videoMain flex justify-center mt-24 mb-24'>
        <div className='videoWrapper w-1/2 flex justify-around'>
          {/* <div className='thumbnail-des'>
            <img src={videoPlay} className='video-play' />
          </div>
          <div>
            <div className='thumbnail-man'><div ><img className='video-play' src={videoPlay} /></div></div>
          </div> */}

          <iframe width="640" height="360" src="https://www.bitchute.com/embed/yIHlyYngEMj0/" title="Video Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div className='shareVideoMain flex justify-center'>
        <div className='w-38'>
          <h3 className='text-3xl font-bold mt-18'>SHARE THE VIDEO</h3>
          <div className='flex justify-around text-6xl mt-10 mb-20'>
            <TelegramShareButton url="https://www.bitchute.com/embed/yIHlyYngEMj0/" quote={"Share on Telegram"}><FontAwesomeIcon icon={faTelegram} /></TelegramShareButton>
            <TwitterShareButton url="https://www.bitchute.com/embed/yIHlyYngEMj0/" quote={"Share on Twitter"}> <FontAwesomeIcon icon={faTwitter} /></TwitterShareButton>
          </div>
        </div>
      </div>

    </div>
  );
};

export default IntroDdn;