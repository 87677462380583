import React from 'react';
import SectionCard from '../SectionCard/SectionCard';
import sectionsData from './sections.json';

const SectionsContainer = () => {
  return (
    <div>
      {sectionsData.map((s, i) => <SectionCard props={s} key={i} />)}
    </div>
  );
};

export default SectionsContainer;