import React from "react";
import DdnImg from "../../assets/header-text.png";
import "./DdnHero.scss";
import slacklogo from "../../assets/slack-logo.png";

const DdnHere = () => {
  return (
    <div className="ddn_container">
      <div className="header-bg-overlay"></div>
      <div className="map"></div>
      <div className="text-container pt-24">
        <h1 className="text-5xl font-bold">
          News by the people, for the people.
        </h1>
        <img className="w-80" src={DdnImg} alt="DDN" />
        <div className="flex flex-col items-center">
          <a href="" className="try-dnn-button mb-5">
            HOST NEWSENGINE
          </a>
          <div className="flex justify-between ddn-buttons">
            <a
              href="https://t.me/newsengineAI"
              className="flex items-center icons"
            >
              {" "}
              <img className="w-5 mr-1" src={slacklogo} alt="slack" /> TELEGRAM
            </a>
            <a href="">SUBSCRIBE</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DdnHere;
