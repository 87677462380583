import React from 'react';
import Ddnlog from '../../assets/dnn-logo.png'
import './Header.scss'

const Header = () => {
  return (
    <div className='fixed bottom has-notice'>
      <div className='flex justify-center header-container' >
        <div className='flex justify-between w-3/4 all-options'>
          <div className='flex justify-between w-1/3' >
            {/* <a href=""><FontAwesomeIcon className='social-icons' icon={faTwitter} /></a>
            <a href=""><FontAwesomeIcon className='social-icons' icon={faMedium} /></a>
            <a href=""><FontAwesomeIcon className='social-icons' icon={faBitcoin} /></a>
            <a href="https://t.me/newsengineAI"><FontAwesomeIcon className='social-icons' icon={faTelegram} /></a>
            <a href=""><FontAwesomeIcon className='social-icons' icon={faGithub} /></a> */}
          </div>
          <div className='flex justify-center w-28' ><img className='w-25' src={Ddnlog} alt=''/></div>
          <div className='flex justify-between w-1/3 header-options' >
            <a href=""></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;